import React from "react";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import Menubar from "../components/Navbar/Menubar_new"
import { Container, Row, Col, Breadcrumb, Card } from "react-bootstrap";
import CarImg from "../images/carimg.jpg";
import SettingIcon from "../images/settingicon.svg";
import CallSupportIcon from "../images/supportlineicon.svg";
import BarChartIcon from "../images/barcharticon.svg";
import TrophyIcon from "../images/trophyicon.svg";
import CommentIcon from "../images/commenticon.svg";
import ShareIcon from "../images/shareicon.svg";
import RightArrowIcon from "../images/rightarrowicon.svg";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";


const carFeatures = [
  {
    id: "1",
    img: SettingIcon,
    name: "All Brands",
    // data: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod",
  },
  {
    id: "2",
    img: CallSupportIcon,
    name: "Free Support",
    // data: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod",
  },
  {
    id: "3",
    img: BarChartIcon,
    name: "Dealership",
    // data: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod",
  },
  
];



const CompanyProfile = () => {
  const {t} = useTranslation();
  
  return (
    <div >
    <Helmet>
        <title>Company Profile - Cube Oman</title>
        <meta name="description" content="Company Profile - Cube Oman" />
        <meta name="keywords" content="Company Profile"/>
        <link rel="canonical" href="https://www.cubeoman.com/company-profile" />
        </Helmet>
      <Header />
      <Menubar />

      <Container fluid className="company-breadcrumb">
        <Row>
          <Col className="breadcrumb-padding" xs={12} md={8}>
            <h1>Company Profile</h1>
            <Breadcrumb>
              <Breadcrumb.Item href="/">{t("menu.home")}</Breadcrumb.Item>
              <Breadcrumb.Item active>
                <div className="blog-breadcrumb-active">Company Profile</div>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col xs={12} md={4}>
            <img src={CarImg} alt="" width="387px" className="mobile-hidden" />
          </Col>
        </Row>
      </Container>
      <div className="carCompanyServ center">
        <Row>
          <Col xs={12} md={6} className="center">
            <h1>Welcome To Car Services</h1>
            <h6 className="pt-2 text-muted">
              Everything you need to build an amazing dealership website.
            </h6>
            <p className="text-muted pt-2 align-left">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use..
            </p>
          </Col>
          
        </Row>
        <Container className="mt-5">
          <Row>
            {carFeatures.map((feature) => (
              <Col key={feature.id} xs={6} md={4}>
                <Card className="profile-card">
                  <Card.Body>
                    <img src={feature.img} alt=""  />
                    <Card.Title className="pt-3">{feature.name}</Card.Title>
                    {/* <Card.Text className="text-muted">{feature.data}</Card.Text> */}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <Col className="missionVisionCont">
        <Row>
          <Col xs={12} md={6}>
            <h1>Our Mission</h1>
            <p className="text-muted">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nos trud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in
              reprehenderit in voluptate.
            </p>
            <p className="text-muted">
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt mollit anim id est laborgum.
            </p>
          </Col>
          <Col xs={12} md={6}>
            <h1>Our Vision</h1>
            <p className="text-muted">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nos trud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in
              reprehenderit in voluptate.
            </p>
            <p className="text-muted">
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt mollit anim id est laborgum.
            </p>
          </Col>
        </Row>
      </Col>
      <Col className="blogNewsCont">
        <h1 className="text-center">Latest News & Blog Feed</h1>
        <p className="text-muted text-center">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam
        </p>
        
      </Col>
      <Footer />
    </div>
  );
};

export default CompanyProfile;
