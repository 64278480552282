import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import { TbArrowsShuffle2 } from "react-icons/tb";
import { FiHeart } from "react-icons/fi";
import CartIcon from "../images/cartplusicon.svg";
import { Card, Button, Row, Col, Breadcrumb, Container, Tabs, Tab, Dropdown, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../config";
import { CartProvider, useCart } from "react-use-cart";
import Swal from 'sweetalert2';
import { Helmet } from "react-helmet";
import Menubar from "../components/Navbar/Menubar_new";
import EditIcon from '../images/Icons/editIcon.png';
import Star from '../images/Icons/star.png'
// import ReactStars from "react-stars";
import RedCross from '../images/Icons/redCross.png'
import QA from '../images/Icons/qa.png';
import Info from '../images/Icons/info.png';
import Close from '../images/Icons/close.png';
import True from '../images/Icons/true.png';
import Product from '../images/product.png';
import RelatedProduct from './RelatedProduct';
import style from '../styles/productDetails.module.css';
import Search from '../images/Icons/Search.png';
import ProductBox from '../components/ProductBox/Productbox';
import { UserContext } from "../App";
import { Tooltip } from "@mui/material";
import BookAppointment from "./GetQuotation";
const data = [
    {
        id: 1,
        img: Product
    },
    {
        id: 2,
        img: Product
    },
    {
        id: 3,
        img: Product
    },
    {
        id: 4,
        img: Product
    },
    {
        id: 5,
        img: Product
    },

]



const ProductDetail = () => {
    const { addItem,
        isEmpty,
        totalUniqueItems,
        totalItems,
        items,
        updateItemQuantity,
        removeItem, } = useCart();
    // console.log(addItem)

    const { t } = useTranslation();
    const navigate = useNavigate()
    let { slug } = useParams();
    const [products, setProduct] = useState('');
    const [producttype, setProductType] = useState([]);
    const [productbrand, setProductBrand] = useState('');
    const [carmake, setCarmake] = useState([]);
    const [carmodel, setCarmodel] = useState([]);
    const [productimage, setProductImage] = useState('');
    const [productData, setProductData] = useState([])
    const [categoryid, setCategoryId] = useState('');
    const [countproduct, setCountProduct] = useState(1);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState();
    const [deviceId, setDeviceId] = useState();
    const token = localStorage.getItem('token');
    let machineId = localStorage.getItem('MachineId');
    const [relatedProduct, setRelatedProduct] = useState([]);
    const { item, setItem } = useContext(UserContext);
    const [modalData, setModalData] = useState();
    const [open, setOpen] = useState(false);
    const [pincode, setPincode] = useState();
    const [shippingData, setShippingData] = useState([]);
    const [shippingCost, setShippingCost] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');
    var storedNames = JSON.parse(localStorage.getItem("names"));

    function getMachineId() {

        let machineId = localStorage.getItem('MachineId');

        if (!machineId) {
            machineId = crypto.randomUUID();
            localStorage.setItem('MachineId', machineId);
        }
        setDeviceId(machineId);
    }
    // console.log(slug)
    useEffect(() => {
        fetchproduct();
        FetchRelatedProduct();
        getMachineId();
    }, [])
    const fetchproduct = async () => {
        await axios.get(BASE_URL + '/api/manage-product/' + slug).then(({ data }) => {
            setProduct(data.data)
            console.log(data.data)
            // console.log('details', data.data)
            setProductData(data.data.product_options)
            axios.get(BASE_URL + '/api/filter-product-with-category/' + data.data.category_id).then(({ data }) => {
                setProductType(data.data)
            });

        });
    }

    const FetchRelatedProduct = async () => {
        await axios.get(BASE_URL + '/api/manage-product-with-category/' + products.category_id).then(({ data }) => {
            setRelatedProduct(data.data)
        });
    }

    async function addtocart(data) {
        if (productbrand.length != 0) {
            data['price'] = Number(productbrand.price);
        } else {
            data['price'] = products.min_price;
        }
        data['product_option_id'] = productbrand.id;
        addItem(data, countproduct)
        // localStorage.setItem("cart", JSON.stringify(data+data));
        // console.log(localStorage.getItem("cart"))
        navigate("/shopping-cart")
    }
    async function handleChangeCar(e) {
        axios.post(BASE_URL + '/api/filter-product-with-brand', {
            "brand_id": e.target.value,
            "product_id": products.id
        }).then(({ data }) => {
            // console.log(data.data)
            if (data.data.length === 0) {
                // console.log(5)
                Swal.fire({
                    text: e.target.value + "Not Found For this product",
                    icon: "success"
                })
                //   setProductBrand(products.min_price)
            } else {

                setProductBrand(data.data);
            }

        });

    }

    function handleSubmit(id, option) {

        setError('');
        const data = { 'product_id': id, 'product_option_id': option, "quantity": countproduct }
        axios.post(BASE_URL + '/api/store-cart', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setItem(data.item)
            setModalData(data)
            setOpen(true)
            if (data.success === true) {
                setError('')
                Swal.fire({
                    text: data.meassge,
                    icon: "success"
                })

                document.getElementById("otherService").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    function handleSubmitOffline(id, option) {
        setError('');
        const data = { "device_id": machineId, id, "product_id": id, "product_option_id": option, "quantity": countproduct }
        axios.post(BASE_URL + '/api/store-cart-unauth', data).then(({ data }) => {
            setItem(data.item)
            setOpen(true)
            setModalData(data)
            if (data.success === true) {
                setError('')

                Swal.fire({
                    text: data.meassge,
                    icon: "success"
                })
                document.getElementById("otherService").reset();

            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            } alert("Added Successfully")
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }


    function BookNow(id, option) {

        setError('');
        const data = { 'product_id': id, 'product_option_id': option, "quantity": countproduct }
        axios.post(BASE_URL + '/api/store-cart', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setItem(data.item)
            setModalData(data)
            setOpen(true)
            if (data.success === true) {
                setError('')
                Swal.fire({
                    text: data.meassge,
                    icon: "success"
                })
                navigate('/shopping-cart')
                document.getElementById("otherService").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    function BookNowOffline(id, option) {
        setError('');
        const data = { "device_id": machineId, id, "product_id": id, "product_option_id": option, "quantity": countproduct }
        axios.post(BASE_URL + '/api/store-cart-unauth', data).then(({ data }) => {
            setItem(data.item)
            setOpen(true)
            setModalData(data)
            if (data.success === true) {
                setError('')

                Swal.fire({
                    text: data.meassge,
                    icon: "success"
                })
                navigate("/shopping-cart")
                document.getElementById("otherService").reset();

            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            } alert("Added Successfully")
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }


    async function getCarModel(id) {
        await axios.get(BASE_URL + '/api/car-model/' + id).then(({ data }) => {
            setCarmodel(data.data)
        })
    }
    const language = localStorage.getItem("language");

    function CheckShoppingbyDelivery() {
        // alert(quantity)
        setError('');
        axios.post(BASE_URL + '/api/check-pincode-delivery', {
            "pincode": pincode,
            "cart_quantity": countproduct,
            "cart_amount": products.product_options[0].price,
            "pincode_billing": pincode
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setShippingData(data)
            setShippingCost(data.shippingCost)
            if (pincode == null) {
                Swal.fire()
            }
            if (data.success === false) {
                setError('')
                Swal.fire({
                    text: data.errors.pincode,
                    icon: "error"
                })
            }
            
            if (data.success === true) {
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }


    return (
        <div className={style.container}>
            <Header />
            <Menubar />
            <Container fluid className="company-breadcrumb flex">

                <Col className={style.p50} xs={12} md={8}>
                    <h1>{t("menu.product")}</h1>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">{t("menu.home")}</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            <div className="blog-breadcrumb-active"> {t("menu.product")}
                            </div>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                
            </Container>
            <div className="col-md-12 col-sm-12 col-xs-12 row">
                <div className="col-md-12 col-sm-12 col-xs-12 row">
                    <div className="col-md-5 col-sm-12 col-xs-12">
                        <div className={style.p20}>
                            <div className={style.productImgContainer}>
                                <img src={BASE_URL + '/storage/' + products.image} alt={products.name} className={style.productImg} />
                            </div>
                            
                            <div className="mt-2">
                                <div className="ProductMoreImages">
                                    {products.product_option_images && products.product_option_images.map((img, i) => (
                                        <div className={style.productImgs}>
                                            <img onClick={() => setProductImage(img.image)} src={BASE_URL + '/storage/' + img.image} alt={products.name} key={i} className={style.proImg} />
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`col-md-7 col-sm-12 col-xs-12 ${style.pCont} OMR{style.borderRight}`}>
                        <div className="PriceAndInfo mt-4">
                            <div className={`flex ${style.space}`}>
                                <h3>{language == "ar" ? products.name_ar && products.name_ar : products.name && products.name}</h3>
                            </div>
                            
                            <p>
                                {language == "ar" ? products.short_description_ar && products.short_description_ar : products.short_description && products.short_description}
                            </p>
                            <Row>
                                <Col md={6} xs={12}> <h5 className={`flex ${style.part}`}> <div className="bold">{t("tools&equipment")}</div> : {products.part_number && products.part_number}  </h5> </Col>
                                <Col md={6} xs={12}> <h5 className={`flex ${style.avail}`}> <div className="bold">{t("availability")}</div>: {t("yes")}</h5></Col>
                            </Row>
                            
                            
                            <div className={`mt-2 ${style.borderTop}`}> </div>
                            <h5 className="flex mt-2"> <div className="bold"> {t("categories")} </div> : {language == "ar" ? products.categories && products.categories.name_ar : products.categories && products.categories.name}  </h5>
                            <div className={`mt-2 ${style.borderTop}`}> </div>
                            <div className="mt-3"> 
                            <Button variant="danger" onClick={() => navigate("/get-quotation")} className={style.button}>
                                {t("RequestQuote")}
                            </Button></div>
                        </div>
                    </div>


                    <div className="col-md-12 col-xs-12 col-sm-12 p50">
                        <Tabs
                                    defaultActiveKey="home"
                                    id=""
                                    className="mb-3 "
                                    type="pills"
                                >
                                    <Tab eventKey="home" title={t("productTab.desc")}>
                                        <div className="Product-Additional-Info card" dangerouslySetInnerHTML={{ __html: language == "ar" ? products.description_ar && products.description_ar : products.description && products.description }}></div>
                                    </Tab>
                                    <Tab eventKey="profile" title={t("productTab.info")}>
                                        <div className="Product-Additional-Info card" dangerouslySetInnerHTML={{ __html: language == "ar" ? products.additional_information_ar : products.additional_information }}>

                                        </div>
                                    </Tab>
                                    
                                    <Tab eventKey="longer-tab" title={t("productTab.review")}>
                                    
                                        <div className="PriceRating ">

                                <div className="Product-Additional-Info card">
                                    {products.rating == '0' ? "" :
                                        <div className={style.ratingCon}><img src={Star} alt="" className={style.ratingImg} />{products.rating}</div>
                                    }
                                    <span className={style.ratingText}>
                                        (3 {t("productTab.review")})
                                    </span>
                                </div>

                            </div>
                                    </Tab>
                                </Tabs>                            
                        </div>   
                </div>
               {/* <div className={`col-md-3 col-sm-12 col-xs-12 ${style.CarCont}`}>
                    <div className="flex mt-2"  >
                        <h4 className="bold" >Car Make & Model </h4>
                    </div>
                    <div className={`flex mt-2 ${style.carMake}`}>
                        <h5  > Car Make: {localStorage.getItem('cardata')} | Car Model: {localStorage.getItem('carmodeldata')} </h5></div>

                    <div className={`mt-2 ${style.border95}`}></div>

                    <div className={`flex mt-4 ${style.zip}`}>
                        <Form.Control type="num" placeholder="Enter ZipCode" className={style.width70} onChange={(e) => setPincode(e.target.value)} />
                        <img src={Search} className={style.search} onClick={() => CheckShoppingbyDelivery()} />
                    </div>
                    <p className={style.zipText}>Enter Zipcode to check delivery</p>
                    <div className={`mt-2 ${style.border90}`}></div>
                    {shippingCost && shippingCost.map((item) => (
                        <div className={`mt-2 ${style.shipText}`}><img src={FreeShip} alt="" className={style.shipIcon} /> {item.name} <Tooltip title={<h6>Deliver in :{item.maximum_days}</h6>}><img src={Info} className={`pointer ${style.info}`} /></Tooltip></div>
                    ))}
                    <div className={`mt-2 ${style.borderTop}`}></div>
                    {storedNames && storedNames.length ?
                        <>
                            <h3 className="mt-4 mb-2">Recent Searches</h3>
                            {storedNames.map((i) => (
                                <div className={style.recent} >
                                    <img src={BASE_URL + '/storage/' + i.image} className={style.recentImg} />
                                    <div className={style.productDetail}>
                                        <h5>
                                            {i.name && i.name}
                                        </h5>
                                        <div className="flex" >
                                            <div className={style.productDiscount}>{i.product_options[0] && i.product_options[0].mrp}</div>
                                            <div className={style.productActual}>{i.product_options[0] && i.product_options[0].price}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                        : ""}
                </div>*/}

            </div> 
            {/* <Col md={12} sm={12} xs={12} className={style.mt8}>
                <Row>
                    <Col md={9} xs={12} sm={12}>
                        <Row>
                            <Col md={5} xs={12} sm={12}>
                                <div className={style.p20}>
                                    <div className={style.productImgContainer}>
                                        <img src={BASE_URL + '/storage/' + products.image} alt={products.name} className={style.productImg} />
                                    </div>
                                    <div className="mt-2" >
                                        <div className="ProductMoreImages">
                                            {products.product_option_images && products.product_option_images.map((img, i) => (
                                                <div className={style.productImgs}>
                                                    <img onClick={() => setProductImage(img.image)} src={BASE_URL + '/storage/' + img.image && img.image} alt={products.name} key={i} className={style.proImg} />
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={7} sm={12} xs={12} className={`${style.pCont} ${style.borderRight}`} >
                                <div className="PriceAndInfo mt-4">
                                    <div className={`flex ${style.space}`}>
                                        <h3>{language == "ar" ? products.name_ar && products.name_ar : products.name && products.name}</h3>
                                    </div>
                                    <div className="PriceRating ">

                                        <div className="flex">
                                            {products.rating == '0' ? "" :
                                                <div className={style.ratingCon}><img src={Star} alt="" className={style.ratingImg} />{products.rating}</div>
                                            }
                                            <span className={style.ratingText}>
                                                (3 {t("productTab.review")})
                                            </span>
                                        </div>

                                    </div>
                                    <div className="PriceRating mt-2">
                                        <div className="flex">
                                            <h4 className={style.lineThrough}>${products.product_options && products.product_options[0].mrp}</h4>
                                            <h4 className={style.discount}>$ {products.product_options && products.product_options[0].price}</h4>
                                            <h5 className={style.off}>{products.product_options && products.product_options[0].discount_percentage}% off</h5>
                                        </div>
                                    </div>
                                    <p>
                                        {language == "ar" ? products.short_description_ar && products.short_description_ar : products.short_description && products.short_description}
                                    </p>
                                    <Row>
                                        <Col md={6} xs={12}> <h5 className={`flex ${style.part}`}> <div className="bold">Part Number</div> : {products.part_number && products.part_number}  </h5> </Col>
                                        <Col md={6} xs={12}> <h5 className={`flex ${style.avail}`}> <div className="bold"> Availability </div>: Yes</h5></Col>
                                    </Row>
                                    <Row className="mt-2 mb-2 ">
                                        <Col md={6} xs={12}> <div className={style.qa}><img src={True} alt="" className={style.qaText} /> Quality Assured</div> </Col>
                                        <Col md={6} xs={12}>   <div className={style.cancel}>Cancellation Allowed<img src={Close} alt="" className={style.cancelText} /> </div> </Col>
                                    </Row>
                                    <Row >
                                        <Col xs={12} md={6} className="flex"> <h4 className="mt-1"> <span className={style.quantityFont}>{t("productTab.qty")}:</span></h4>
                                            <div className={style.borderStyle}>
                                                <h1 className={style.dec} onClick={() => setCountProduct(countproduct == 1 ? countproduct : countproduct - 1)}>-</h1>
                                                <h4 className={style.quanity}>{countproduct}</h4>
                                                <h2 className={style.inc} onClick={() => setCountProduct(countproduct + 1)}>+</h2>
                                            </div>
                                        </Col>
                                        <Col md={3} xs={6} className={style.Btn}>
                                            <Button className="CartBtn" onClick={token ? () => handleSubmit(products.product_options[0].product_id, products.product_options[0].id) : () => handleSubmitOffline(products.product_options[0].product_id, products.product_options[0].id)}>{t("productTab.cartBtn")}</Button> </Col>
                                        <Col md={3} xs={6} className={style.Btn}> <button className="MoreBtn" onClick={token ? () => BookNow(products.product_options[0].product_id, products.product_options[0].id) : () => BookNowOffline(products.product_options[0].product_id, products.product_options[0].id)}> {t("productTab.buyBtn")}</button> </Col>
                                    </Row>
                                    <div className={`mt-4 ${style.borderTop}`}> </div>
                                    <h5 className="flex mt-2"> <div className="bold">Categories</div> : {products.categories && products.categories.name}  </h5>
                                </div>
                                <div className={`mt-2 ${style.borderTop}`}> </div>
                                <Row className={style.space}>
                                    <Col md={6} xs={12}> <div className={`mt-2 ${style.cod}`}><img src={True} alt="" className={style.codImg} /> <span className="mt-1">Cash On Delivery</span></div> </Col>
                                    <Col md={6} xs={12}> <div className={`mt-2 ${style.replace}`}><img src={Repl} alt="" className={style.replaceImg} /><span className="mt-1"> 7-10 days  Replacement </span>  </div> </Col>
                                </Row>

                            </Col>
                        </Row>
                        <Col md={12}>
                            <Col md={8} className={style.p50}>
                                <Tabs
                                    defaultActiveKey="profile"
                                    id=""
                                    className="mb-3 "
                                    type="pills"
                                >
                                    <Tab eventKey="home" title={t("productTab.desc")}>
                                        <div className="Product-Additional-Info card" dangerouslySetInnerHTML={{ __html: language == "ar" ? products.description_ar && products.description_ar : products.description && products.description }}></div>
                                    </Tab>
                                    <Tab eventKey="profile" title={t("productTab.info")}>
                                        <div className="Product-Additional-Info card" dangerouslySetInnerHTML={{ __html: language == "ar" ? products.additional_information_ar : products.additional_information }}>

                                        </div>
                                    </Tab>
                                    <Tab eventKey="longer-tab" title={t("productTab.review")}>
                                        <div className="Product-Additional-Info card" dangerouslySetInnerHTML={{ __html: language == "ar" ? products.additional_information_ar && products.additional_information_ar : products.additional_information && products.additional_information }}>

                                        </div>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Col>
                    </Col>
                    <Col md={3} sm={12} xs={12} className={style.CarCont}>
                        <div className="flex mt-2"  >
                            <h4 className="bold" >Car Make & Model </h4>
                        </div>
                        <div className={`flex mt-2 ${style.carMake}`}>
                            <h5  > Car Make: {localStorage.getItem('cardata')} | Car Model: {localStorage.getItem('carmodeldata')} </h5></div>

                        <div className={`mt-2 ${style.border95}`}></div>

                        <div className={`flex mt-4 ${style.zip}`}>
                            <Form.Control type="num" placeholder="Enter ZipCode" className={style.width70} onChange={(e) => setPincode(e.target.value)} />
                            <img src={Search} className={style.search} onClick={() => CheckShoppingbyDelivery()} />
                        </div>
                        <p className={style.zipText}>Enter Zipcode to check delivery</p>
                        <div className={`mt-2 ${style.border90}`}></div>
                        {shippingCost && shippingCost.map((item) => (
                            <div className={`mt-2 ${style.shipText}`}><img src={FreeShip} alt="" className={style.shipIcon} /> {item.name} <Tooltip title={<h6>Deliver in :{item.maximum_days}</h6>}><img src={Info} className={`pointer ${style.info}`} /></Tooltip></div>
                        ))}
                        <div className={`mt-2 ${style.borderTop}`}></div>
                        {storedNames && storedNames.length ?
                            <>
                        <h3 className="mt-4 mb-2">Recent Searches</h3>
                                {storedNames.map((i) => (
                                    <div className={style.recent} >
                                        <img src={BASE_URL + '/storage/' + i.image} className={style.recentImg} />
                                        <div className={style.productDetail}>
                                            <h5>
                                                {i.name && i.name}
                                            </h5>
                                            <div className="flex" >
                                                <div className={style.productDiscount}>{i.product_options[0] && i.product_options[0].mrp}</div>
                                                <div className={style.productActual}>{i.product_options[0] && i.product_options[0].price}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                            : ""}

                    </Col>
                </Row>
            </Col> */}

            <br />
            {relatedProduct && relatedProduct.length ?
                <RelatedProduct>
                    {relatedProduct.map(i => (
                        <Col md={3} xs={12}>
                            <ProductBox
                                discountPrice={i.product_options[0] && i.product_options[0].price}
                                actualPrice={i.product_options[0] && i.product_options[0].mrp}
                                name={i.name && i.name}
                                discount_perc={i.product_options[0] && i.product_options[0].discount_percentage}
                                img={i.image}
                            />
                        </Col>
                    ))}
                </RelatedProduct>
                : ""}
            <Modal show={open} onHide={() => setOpen(false)} centered>
                {/* <Modal.Header closeButton></Modal.Header> */}
                <Modal.Body>
                    <div className='center mt-2'>{modalData ? modalData.meassge && modalData.meassge == "Already Added To Cart" ?
                        <div className="text-danger">You have already added this product in the cart, do you want to add more? <span className="pointer text-blue" onClick={() => navigate('/shopping-cart')}>Yes</span> </div>
                        :
                        modalData.meassge : ''}</div>

                </Modal.Body>

            </Modal>
            <Footer />
        </div>
    );
};

export default ProductDetail;
