import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Nav,
  Accordion,
} from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../config";
import RightArrowIcon from "../images/rightarrowicon.svg";
import HomeIcon from "../images/homegroup.svg";
import { Link } from "react-router-dom";
import Menubar from "../components/Navbar/Menubar_new";
import style from '../styles/home.module.css';
import Modal from 'react-bootstrap/Modal';
import BannerAndForm from '../components/HomepageComponents/BannerAndForm';
import OtherServices from "../components/HomepageComponents/OtherServicesHome";
import FeaturedProducts from '../components/HomepageComponents/FeaturedProducts';
import TopProducts from "../components/HomepageComponents/TopProducts";
import BestDeals from "../components/HomepageComponents/BestDeals";
import Testomial from '../images/testimonial.png';
import { Helmet } from "react-helmet";
import { AiTwotonePhone, AiOutlineWhatsApp, AiOutlineMail } from 'react-icons/ai';
import { UserContext } from "../App";
import { AiOutlineVerticalAlignBottom, AiTwotoneEye, AiFillHome } from "react-icons/ai";
import { faTrashAlt, faEdit, faEye, faComment, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import Slider from "react-slick";



const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [model, setModal] = useState()
  const [blogs, setBlogs] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [prcategory, setProcategory] = useState([]);
  const [producttop, setProducttop] = useState([]);
  const [product, setProduct] = useState([]);
  const [cart, setCart] = useState(0);
  const [teams, setTeams] = useState([]);
  const [centerDetails, setCenterDetails] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const [homeData, setHomeData] = useState([]);
  const [otherServiceData, setOtherServiceData] = useState([]);
  const [valueServiceData, setValueServiceData] = useState([]);
  const [carServiceData, setcarServiceData] = useState([]);
  const [imageBase, setImageBase] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [shippingData, setShippingData] = useState([]);
  const [dealerShip, setDealerShip] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [spareParts, setSpareParts] = useState([]);
  const { item, setItem } = useContext(UserContext);
  useEffect(() => {
    fetchBlogs();
    fetchFaqs();
    fetchproductcategory();
    getProduct(0);
    fetchteams();
    fetchallproduct();
    CenterDetails();
    MetaData();
    HomeWidget();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
          breakpoint: 400, // For screens larger than 1024px
          settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
          },
      },
      {
          breakpoint: 768, // For screens larger than 768px
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
          },
      },
  ],
  };

  const fetchBlogs = async () => {
    await axios.get(BASE_URL + '/api/latest-blogs').then(({ data }) => {
      setBlogs(data.data)
    })
  }

  const fetchFaqs = async () => {
    await axios.get(BASE_URL + '/api/faqs').then(({ data }) => {
      setFaqs(data.data)
    })

  }
  const fetchproductcategory = async () => {
    await axios.get(BASE_URL + '/api/product-categories').then(({ data }) => {
      setProcategory(data.data)

    })
  }

  const fetchteams = async () => {
    await axios.get(BASE_URL + '/api/manage-teams').then(({ data }) => {
      setTeams(data.data);
    })
  }

  const CenterDetails = async () => {
    await axios.get(BASE_URL + '/api/contact-us-home').then(({ data }) => {
      setCenterDetails(data.data);
      // console.log('y', data.data)
    })
  }

  const fetchallproduct = async () => {
    await axios.get(BASE_URL + '/api/top-rated-product').then(({ data }) => {
      setProduct(data.data);
    })
  }
  async function getProduct(id) {
    // console.log(id);
    await axios.get(BASE_URL + '/api/top-rated-product/' + id).then(({ data }) => {
      setProducttop(data.data)
    })
  }

  async function MetaData() {
    // console.log(id);
    await axios.get(BASE_URL + '/api/header-settings/').then(({ data }) => {
      setMetaData(data.data)
    })
  }

  async function HomeWidget() {
    await axios.get(BASE_URL + '/api/homepagewidget').then(({ data }) => {
      setImageBase(data.data.image_base_url)
      setHomeData(data.data.data[0])
      setOtherServiceData(data.data.data[1])
      setcarServiceData(data.data.data[2])
      setBrandData(data.data.data[3])
      setValueServiceData(data.data.data[6])
      setBlogData(data.data.data[7])
      setSpareParts(data.data.data[8])
    })
  }




  const language = localStorage.getItem("language");
  return (
    <div className="overflow">
      <Helmet>
        <meta name="title" content={metaData.meta_title} />
        <meta name="description" content={metaData.meta_description} />
        <meta name="keywords" content={metaData.meta_keywords} />
        <meta name="canonical-tags" content={metaData.canonical_tags} />
        <meta name="twitter-cards" content={metaData.twitter_cards} />
        <meta name="og-tags" content={metaData.og_tags} />
      </Helmet>
      <Header data={cart} />
      <Menubar />
      <hr />
      {metaData.show_in_footer_whatsapp_number && metaData.show_in_footer_whatsapp_number == 'on' ?
        <>
          <div className="floating">{t("Chatwithus")}</div>
          <a target="_blank" href={`https://wa.me/${metaData.whatsapp_number}`} className="floating-icon"><AiOutlineWhatsApp className={style.whatsappIcon} /></a>
        </>
        : ""}
      <Container>
        <BannerAndForm />
        
      </Container>
      <br />
      <BestDeals />
      
      <OtherServices
        otherServiceHeading={localStorage.getItem("language") == "ar" ? otherServiceData.heading_ar && otherServiceData.heading_ar : otherServiceData.heading && otherServiceData.heading}
        otherServiceContent={localStorage.getItem("language") == "ar" ? otherServiceData.content_ar && otherServiceData.content_ar : otherServiceData.content && otherServiceData.content}
        carService={localStorage.getItem("language") == "ar" ? carServiceData.heading_ar && carServiceData.heading_ar : carServiceData.heading && carServiceData.heading}
        serviceContent={localStorage.getItem("language") == "ar" ? carServiceData.content_ar && carServiceData.content_ar : carServiceData.content && carServiceData.content}
        carImage={imageBase + '/' + carServiceData.image}
      
      />
      <FeaturedProducts />
      <TopProducts />
     
      
      <Container fluid className="locationCont" >
        <div className="container">
          <div className="location-section">
            <div className="location-content-section">
              <div className="address-bg mt-2"><i class="fa fa-map-marker" aria-hidden="true"></i><p>Address</p></div>
              <label className="mt-2" dangerouslySetInnerHTML={{
                __html: localStorage.getItem('language') == 'ar' ?
                  centerDetails.address_ar && centerDetails.address_ar : centerDetails.address && centerDetails.address
              }}>
              </label>
              <div className="address-bg mt-2"><i class="fa fa-phone" aria-hidden="true"></i><p>Mobile Number</p></div>
              <label className="mt-2">
                {centerDetails && centerDetails.contact_number}
              </label>
              <div className="address-bg mt-2"><i class="fa fa-whatsapp" aria-hidden="true"></i><p>Whatsapp Number</p></div>
              <label className="mt-2">
                {centerDetails && centerDetails.whatsapp_number}
              </label>
              <div className="address-bg mt-2"><i class="fa fa-envelope" aria-hidden="true"></i><p>Mail Id</p></div>
              <label className="mt-2">
                {centerDetails && centerDetails.email}
              </label>
            </div>
            <div dangerouslySetInnerHTML={{ __html: centerDetails.map_url && centerDetails.map_url }}></div>
          </div>
        </div>

        

      </Container>
      {teams.length > 1 ?
        <div className="container-fluid testimonials-bg">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                {teams && teams.map((data) => (
                  <div className="col-md-4">
                    <div className="testi-card-bg">
                      <p>{language == 'ar' ? data.message_ar && data.message_ar : data.message && data.message}</p>
                      <Col className="mt-1">
                        {data && data.rating == '0' ? "" : <Col> {t("shopFilters.Rating")}:   {data && data.rating == '1' ? <FontAwesomeIcon icon={faStar} color="orange" /> : data && data.rating == '2' ? <><FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /></> :
                          data && data.rating == '3' ? <> <FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /><FontAwesomeIcon icon={faStar} color="orange" /> </> :
                          data && data.rating == '4' ? <> <FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /><FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /> </> :
						              data && data.rating == '5' ? <> <FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /><FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /> </> :
                            <><FontAwesomeIcon icon={faStar} color="orange" /> <FontAwesomeIcon icon={faStar} color="orange" /><FontAwesomeIcon icon={faStar} color="orange" /><FontAwesomeIcon icon={faStar} color="orange" /> </>} </Col>}
                      </Col>
                      <div className="CardProfile mt-4">
                        <img src={BASE_URL + '/storage/' + data.image} alt="" />
                        <p>
                          <big>  {language == "ar" ? data.first_name_ar && data.first_name_ar : data.first_name && data.first_name} {language == 'ar' ? data.last_name_ar && data.last_name_ar : data.last_name && data.last_name}</big>
                        </p>
                      </div>
                    </div>
                  </div>
                 
                ))}
              </Slider>
            </div>
          </div>
        </div> : ""}
      
      
      <Footer />
    </div>
  );
};

export default Home;
