import React, { useState, useEffect, useRef, useContext } from 'react';
import ProductBox from '../ProductBox/Productbox';
import SliderwithMenu from '../ProductBox/Slider';
import axios from 'axios';
import { Col, Nav, Container, Card, Button, Row, Modal, Form, ModalHeader, ModalBody } from 'react-bootstrap';
import Product from '../../images/product.png';
import { BASE_URL } from '../../config';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import style from '../../styles/slider.module.css';
import ArrowRight from '../../images/Icons/ArrowRight.png';
import ArrowLeft from '../../images/Icons/ArrowLeft.png';
import RightArrow from '../../images/Icons/RightArrow.png';
import LeftArrow from '../../images/Icons/LeftArrow.png';
import Swal from 'sweetalert2';
import EditIcon from '../../images/Icons/editIcon.png';
import { UserContext } from '../../App';
import FeaturedImg from "../../images/feature-image.png";
import { useTime } from 'react-timer-hook';

const sideScroll = (
  element,
  speed,
  distance,
  step
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step * 2 - 10;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};



const dataScroll = (
  element,
  speed,
  distance,
  step
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step * 2 - 10;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

function TopProducts() {

  const contentWrapper = useRef(null);

  const dataWrapper = useRef(null);

  const [prcategory, setProcategory] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  let machineId = localStorage.getItem('MachineId');
  const [productId, setProductId] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [error, setError] = useState();
  const [show, setShow] = useState();
  const [carMake, setCarMake] = useState([]);
  const [carModel, setCarModel] = useState([]);
  const [selectedCarMake, setSelectedCarMake] = useState(localStorage.getItem("carmake"));
  const [selectedCarModel, setSelectedCarModel] = useState(localStorage.getItem("carmodel"));
  const [make, setMake] = useState(false);
  const [modal, setModal] = useState(false);
  const [state, setState] = useState();
  const [stateId, setIdState] = useState();
  const [carData, setCarData] = useState(false);
  const [slugId, setSlugId] = useState();
  const { item, setItem } = useContext(UserContext);


  useEffect(() => {
    fetchproductcategory();
    fetchproduct(0);
    getMachineId();
    fetchCarMake();
  }, []);

  const {
    seconds,
    minutes,
    hours,
    ampm,
    days,
  } = useTime({ format: '12-hour' });

  const token = localStorage.getItem('token');

  const [products, setProducts] = useState([]);
  const language = localStorage.getItem("language");

  async function fetchproductcategory() {
    axios.get(BASE_URL + '/api/product-categories/', {
    }).then(({ data }) => {
      setProcategory(data.data);
    })
  }


  async function fetchproduct(id) {
    axios.get(BASE_URL + '/api/featured-product/' + id, {
    }).then(({ data }) => {
      setProducts(data.data);
    })
  }

  const fetchCarMake = async () => {
    await axios.get(BASE_URL + '/api/car-make').then(({ data }) => {
      setCarMake(data.data)
    })
  }
  async function fetchCarModel(data) {
    const car = new String(data)

    const carMake = car.split("_")
    const CarMakeId = carMake[0]
    setSelectedCarMake(localStorage.setItem('cardata', carMake[1]));

    await axios.get(BASE_URL + '/api/car-model/' + CarMakeId).then(({ data }) => {
      setCarModel(data.data)
    }).catch(({ response }) => {

    })
  }



  function getMachineId() {
    let machineId = localStorage.getItem('MachineId');
    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem('MachineId', machineId);
    }
  }

  function handleSubmitOffline(id, option) {
    setOpen(true)
    setShow(false)
    setError('');
    const data = { "device_id": machineId, "product_id": id, "product_option_id": option }
    axios.post(BASE_URL + '/api/store-cart-unauth', data).then(({ data }) => {
      setModalData(data)
      setItem(data.item)
      if (data.status === "success") {
        setError('')

        Swal.fire({
          text: data.message,
          icon: "success"
        })
        navigate("/shopping-cart")
        document.getElementById("otherService").reset();

      }
      if (data.status === "error") {
        Swal.fire({
          text: data.message,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data)
      if (response.status === "422") {
        setError(response.data.message)
      }
    });
  }

  function handleSubmit(id, option) {
    setOpen(true)
    setShow(false)
    setError('');
    const data = { 'product_id': id, 'product_option_id': option }
    axios.post(BASE_URL + '/api/store-cart', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setItem(data.item)
      setModalData(data)
      if (data.status === "success") {
        setError('')
        Swal.fire({
          text: data.message,
          icon: "success"
        })
        navigate("/")
        document.getElementById("otherService").reset();
      }
      if (data.status === "error") {
        Swal.fire({
          text: data.message,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data)
      if (response.status === "422") {
        setError(response.data.message)
      }
    });
  }

  return (
    
    <div className="container-fluid featuredproducts-bg">
    <div className="container">
      <div className='row'>
        <Col md={8} xs={12} className={style.container}>
          <Col>
            <Row>
              <Col md={6} className={`flex ${style}`} >
              
                <div className={style.fontsize}> {t("productTab.best")} {t("productTab.deals")}</div>
                
              </Col>
              <Col md={6} sm={12} xs={12} className="mt-2">
                <Col md={12} sm={12} xs={12} className={`flex `}>
                  <div className={`pointer ${style.ScrollButton}`} onClick={() => { dataScroll(dataWrapper.current, 25, 100, -10); }}>
                    <img src={ArrowLeft} alt="Right" className="mb-1" />
                  </div>
                  <div className={style.contentWrapper} ref={dataWrapper}>
                    <div className="flex">
                      <div onClick={() => fetchproduct(0)} className='pointer'>{t("productTab.all")}</div>
                      {prcategory.map((category) => (
                        <div className={style.menuItem} eventKey={category.id && category.id} onClick={() => fetchproduct(category.id)}>
                          {localStorage.getItem("language") == 'ar' ? category.name_ar && category.name_ar : category.name && category.name}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={`pointer ${style.ScrollButton}`} onClick={() => {
                    dataScroll(dataWrapper.current, 25, 100, 10);
                  }}>
                    <img src={ArrowRight} alt="Right" className="mb-1" />
                  </div>
                </Col>
              </Col>
            </Row>
          </Col>

          <div className="flex mt-2">
            
            <div className={style.contentWrapper} ref={contentWrapper}>
              {products.map((i) => (
                <>
                  <Col md={4} sm={4} xs={6} className='featured-products-section'>
                  <ProductBox
            name={localStorage.getItem('language') == 'ar' ? i.name_ar && i.name_ar : i.name && i.name}
            img={i.image}
            BtnCart={t("productTab.cartBtn")}
            
            productDetail={localStorage.getItem('cardata') && localStorage.getItem('carmodeldata') ? () => navigate("/product-detail/" + i.slug) : () => {navigate("/product-detail/" + i.slug)}}
          />
        </Col>
                </>
              ))}
              
              
             

            </div>
            <div onClick={() => {
              sideScroll(contentWrapper.current, 25, 100, 10);
            }}>
              <img src={RightArrow} alt="Right" className={`pointer ${style.Scroller}`} />
            </div>
          </div>
        </Col>
        <Col md={4} xs={12} className="timer-pd">
          <div className='deals-section'>
          <h1 className={style.fontsize}> {t("productTab.dealsofthemonth")}</h1>
            <img src={FeaturedImg} alt="" />
            <div style={{ textAlign: 'center' }}>
              <div className='timer-section'>
                <div className='timer-bg'>
                  <p>Days</p>
                  <p>4</p>
                </div>
                <div className='timer-bg'>
                  <p>Hours</p>
                  <p>{hours}</p>
                </div>
                <div className='timer-bg'>
                  <p>Mins</p>
                  <p>{minutes}</p>
                </div>
                <div className='timer-bg'>
                  <p>Sec</p>
                  <p>{seconds}</p>
                </div>
                {/* <span>Days : {hours}</span>:<span>{minutes}</span>:<span>{seconds}</span><span>{ampm}</span> */}
              </div>
            </div>
          </div>

        </Col>
      </div>
    </div></div>
  )
}

export default TopProducts;