import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';


export default function OffCanvasExample({ name,className,variant,title,children,description,handleData,setPrice,setPriceNull,handleNull }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant={variant} onClick={() => {setShow(true); handleData(setPrice(null)) ; handleNull(setPriceNull(null))}} className={className}>
        {name}
      </Button>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
           <div className='drawer-title'>{title}</div> 
            <div className="mt-1 pack-des">{description}</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
         {children}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

