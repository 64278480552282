import React, { useState, useEffect,useContext } from "react";
import { slide as Menu } from "react-burger-menu";
import { Popover, Overlay, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/Header_new";
import Menubar from "../components/Navbar/Menubar_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import {
  Container,
  Button,
  Row,
  Col,
  Table,
  Navbar,
  Dropdown,
  Nav,
  Form,
  Offcanvas,
  Breadcrumb,
  Card,
  Modal
} from "react-bootstrap";
import { AiFillClockCircle } from "react-icons/ai";
import { GrTooltip } from "react-icons/gr"
import EngineRepair from "../images/engrep.svg";
import BrakeRepair from "../images/brkrep.svg";
import CarWash from "../images/washcar.svg";
import ElectricSystem from "../images/elecsys.svg";
import RightIcon from "../images/rightcircle.svg";
import TireBalance from "../images/tireimg.svg";
import SuspensionRepair from "../images/susrep.svg";
import BackLightRepair from "../images/lightrep.svg";
import ACService from "../images/acrep.svg";
import CarImg from "../images/carimg.jpg";
import style from '../styles/ourservice.module.css';
import Time from '../images/Icons/time.png';
import EditIcon from '../images/Icons/editIcon.png';
import axios from "axios";
import { BASE_URL } from "../config";
import Swal from "sweetalert2";
import Drawer from '../components/Drawer/Drawer';
import swal from "sweetalert";
import {UserContext} from '../App';
const OtherServices = () => {
  var styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '39px',
      height: '30px',
      left: '36px',
      top: '200px'
    },
    bmBurgerBars: {
      background: '#373a47'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#bdc3c7'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
      width: '35rem',
      boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
    },
    bmMenu: {
      background: '#F3F4F8',
      fontFamily: 'Sarabun',
      padding: '2.5em 1.5em 0',
      fontSize: '1.10em'
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em'
    },
    bmItem: {
      display: 'inline-block'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  }


  const { t } = useTranslation();
  const fuelType = [
    {
      id: 1,
      name: t("fuelType.petrol")
    },
    {
      id: 1,
      name: t("fuelType.diesel")
    },
    {
      id: 1,
      name: t("fuelType.cng")
    },
    {
      id: 1,
      name: t("fuelType.ev")
    }
  ]
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [service, setService] = useState([]);
  const [cart, setCart] = useState([])
  const [serviceOptionId, setserviceOptionId] = useState([]);
  const [userId, setUserId] = useState();
  const [error, setError] = useState();
  const token = localStorage.getItem('token');
  const [deviceId, setDeviceId] = useState('');
  const [carOrigin, setCarOrigin] = useState([]);
  const [carCylinder, setCarCylinder] = useState([]);
  const [oilgrade, setOilGrade] = useState([]);
  const [carmake, setCarMake] = useState([]);
  const [carmodel, setCarModel] = useState([]);
  const [selectedCarMake, setSelectedCarMake] = useState();
  const [selectedCarModel, setSelectedCarModel] = useState();
  const [selectedOrigin, setSelectedOrigin] = useState();
  const [selectedCylinder, setSelectedCylinder] = useState();
  const [selectedOil, setSelectedOil] = useState();
  const [selectedFuel, setSelectedFuel] = useState();
  let machineId = localStorage.getItem('MachineId');
  const [modalData, setModalData] = useState();
  const [open, setOpen] = useState();
  const [editMake, setEditMake] = useState();
  const [editModel, setEditModel] = useState();
  const [editFuel, setEditFuel] = useState();
  const [price, setPrice] = useState();
  const [carmakeId, setcarMakeId] = useState();
  const [carmodelId, setCarModelId] = useState();
  const [serviceId, setServiceId] = useState();
  const [homeData, setHomeData] = useState([]);
  const model = new String(selectedCarModel)
  const carmodeldata = model.split("_")
  const modelId = carmodeldata[0]
  const modelName = carmodeldata[1]
  // console.log(modelId)
  const { itemservice, setItemService,servicetype, setServiceType } = useContext(UserContext);
  // setCarMakeg(selectedCarMake);
  // setCarModelg(model);
  localStorage.setItem('selectedCarMake', selectedCarMake)
  localStorage.setItem('selectedCarModel', modelName)
  localStorage.setItem('selectedFuel', selectedFuel)
  localStorage.setItem('serviceType', "other")






  const ProceedValueSummary = () => {
    {
      selectedCarMake && selectedCarModel && selectedFuel ?
        navigate('/service-cart-summary',
          {
            state: {
              carmake: selectedCarMake ? selectedCarMake : '',
              carmodel: modelName ? modelName : '',
              carfuel: selectedFuel ? selectedFuel : '',
              service: "other" ? "other" : '',
            }
          }
        )
        : alert('Please Select Car Make, Modal and Fuel')
    }
  }


  useEffect(() => {
    fetchservice();
    getMachineId();
    fetchCarMake();
    fetchOrigin();
    fetchCylinder();
    fetchOilGrade();
    fetchCarModel();
    changePrice();
    fetchCarModels();
    fetchFuels();
    HomeWidget();
  }, [])

  const fetchservice = async () => {
    // console.log("hello")
    await axios.get(BASE_URL + '/api/other-services').then(({ data }) => {
      // console.log("hello1")
      setService(data.data);
      // console.log("hi", data.data)
    })
  }

  async function HomeWidget() {
    await axios.get(BASE_URL + '/api/homepagewidget').then(({ data }) => {
      setHomeData(data.data.data[1])
    })
  }

  function getMachineId() {

    let machineId = localStorage.getItem('MachineId');

    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem('MachineId', machineId);
    }

  }



  function changePrice(id) {
    setError('');
    axios.post(BASE_URL + '/api/filter-services', {
      "service_id": id,
      "brand_id": carmakeId,
      "brandmodel_id": modelId
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setPrice(data.data)
      if (data.status === "success") {
        setError('')
        Swal.fire({
          text: data.message,
          icon: "success"
        })
      }
    })
  }

  function getcartempty(data){
    swal({
      title: "Are you sure you want to change the Car Make & Model?",
      text: "If you do this Services added in your cart will be removed",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((value) => {
      if(value == true){
      fetchCarModel(data);
      localStorage.setItem('carmake',data);
      if(token){
        handledelete();
      }else{
        handledeleteunauth();
      }
      }  
});
  }
  function getcartemptymodel(data){
    swal({
      title: "Are you sure you want to change the Car Make & Model?",
      text: "If you do this Services added in your cart will be removed",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((value) => {
     if(value == true){
      setSelectedCarModel(data);
      localStorage.setItem('carmodel',data);
      if(token){
        handledelete();
      }else{
        handledeleteunauth();
      }
     }
     
      
});
  }
  function handledelete(){
    axios.post(BASE_URL + '/api/remove-all-item-from-cart-service','',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({data})=>{
      setItemService(0)
    })
  }

  function handledeleteunauth(){
    const data = { "device_id": machineId };
    axios.post(BASE_URL + '/api/remove-all-item-from-cart-service-unauth',data).then(({data})=>{
      setItemService(0)
    })
  }
  function handleSubmit(id, option) {
    if(servicetype=="value_added_services"){
      handledelete();
    }
    setOpen(true)
    setError('');
    const data = { 'service_id': option, 'service_option_id': id,'service_type':"other_services" }
    axios.post(BASE_URL + '/api/store-cart-service', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setModalData(data)
      setItemService(data.item)
      setServiceType('other_services')
      localStorage.setItem('service', 'other_services')
      if (data.status === "success") {
        setError('')
        Swal.fire({
          text: data.message,
          icon: "success"
        })
        navigate("/")
        document.getElementById("otherService").reset();
      }
      if (data.status === "error") {
        Swal.fire({
          text: data.message,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data)
      if (response.status === "422") {
        setError(response.data.message)
      }
    });
  }



  function handleSubmitOffline(id, option) {
    if(servicetype=="value_added_services"){
      handledelete();
    }
    setOpen(true)
    setError('');
    const data = { "device_id": machineId, 'service_id': option, 'service_option_id': id,'service_type':"other_services" }
    axios.post(BASE_URL + '/api/store-cart-service-unauth', data, {}).then(({ data }) => {
      setModalData(data)
      setItemService(data.item)
      setServiceType('other_services')
      localStorage.setItem('service', 'other_services')
      if (data.status === "success") {
        setError('')
        Swal.fire({
          text: data.message,
          icon: "success"
        })
        navigate("/")
        document.getElementById("otherService").reset();
      }
      if (data.status === "error") {
        Swal.fire({
          text: data.message,
          icon: "error"
        })
      }

    }).catch(({ response }) => {
      setError(response.data)
      if (response.status === "422") {
        setError(response.data.message)
      }
    });
  }


  const fetchCarMake = async () => {
    await axios.get(BASE_URL + '/api/car-make').then(({ data }) => {
      setCarMake(data.data)
    })
  }
  async function fetchCarModel(data) {
    const car = new String(data ? data :localStorage.getItem('carmake'))
    const carMake = car.split("_")
    const CarMakeId = carMake[0]
    setcarMakeId(carMake[0])
    setSelectedCarMake(carMake[1]);
    await axios.get(BASE_URL + '/api/car-model/' + CarMakeId).then(({ data }) => {
      setCarModel(data.data)
    }).catch(({ response }) => {

    })
  }

  const fetchOrigin = async () => {
    await axios.get(BASE_URL + '/api/car-origin/').then(({ data }) => {
      // console.log("car2")
      setCarOrigin(data.data);
      // console.log("car Origin", data.data)
    })
  }

  const fetchCylinder = async () => {
    await axios.get(BASE_URL + '/api/car-cylinder/').then(({ data }) => {
      // console.log("car2")
      setCarCylinder(data.data);
      // console.log("car cylinder", data.data)
    })

  }

  const fetchOilGrade = async () => {
    await axios.get(BASE_URL + '/api/oil-grade/').then(({ data }) => {
      // console.log("car2")
      setOilGrade(data.data);
      // console.log("car oil grade", data.data)
    })

  }
  function fetchCarModels(data){
    setSelectedCarModel(data ? data : localStorage.getItem('carmodel'))
  }

  function fetchFuels(data){
    setSelectedFuel(data ? data : localStorage.getItem('fuel'))
  }


  return (
    <div className="overflow">
      <Header />
      <Menubar />
      <Container fluid className="company-breadcrumb">
        <Row>
          <Col className="breadcrumb-padding" xs={12} md={8}>
            <h1>{t("menu.service")}</h1>
            <Breadcrumb>
              <Breadcrumb.Item href="/">{t("menu.home")}</Breadcrumb.Item>
              <Breadcrumb.Item active><div className="blog-breadcrumb-active">{t("menu.service")}</div></Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col xs={12} md={4}>
            <img src={CarImg} alt="" width="387px" className="mobile-hidden" />
          </Col>
        </Row>
      </Container>
      <Col md={12} xs={12}>
        <Container fluid>
          <div className={`text-center pt-5 ${style.serviceHeading}`}>{homeData.heading && homeData.heading}</div>
          <div className={`text-muted text-center mt-2 pt-2 ${style.des}`} dangerouslySetInnerHTML={{__html: homeData.content && homeData.content  }}>
      
          </div>

          <Col md={12} xs={12} className={style.p20} >


            <Row>
              <Col md={2} xs={12} sm={6} className="ipadinput">
              
                <Form.Select value={localStorage.getItem('carmake')} className="mt-4 input-drawer" size="md"  onChange={(e) => {getcartempty(e.target.value); }}>
                  <option value="0">{t("menu.serviceDetail.ac.selectMake")}</option>
                  {carmake.map((item) => (
                    <option key={item.id} value={item.id + "_" + item.name}>{localStorage.getItem('language') == 'ar' ? item.name_ar && item.name_ar : item.name && item.name}</option>
                  ))}
                </Form.Select>
              </Col>
              <Col md={2} xs={12} sm={6} className="ipadinput">
                <Form.Select value={localStorage.getItem('carmodel')} className="mt-4 input-drawer" size="md" onChange={(e) => {getcartemptymodel(e.target.value)}}>
                  <option value="0">{t("menu.serviceDetail.ac.selectModel")}</option>
                  {carmodel.map((item) => (
                    <option  key={item.id} value={item.id + "_" + item.name}>{localStorage.getItem('language') == 'ar' ? item.name_ar && item.name_ar : item.name && item.name}</option>
                  ))}
                </Form.Select>
              </Col>
              <Col md={2} xs={12} sm={6} className="ipadinput">
              
                <Form.Select value={localStorage.getItem('fuel')} className="mt-4 input-drawer" size="md" onChange={(e) => { fetchFuels(e.target.value);localStorage.setItem('fuel',e.target.value)  }}>
                  <option value="0">{t("menu.serviceDetail.ac.selectfuel")}</option>
                  {fuelType.map((item) => (
                    <option  key={item.id} value={item.name}>{item.name}</option>
                  ))}
                </Form.Select>
              </Col>

            </Row>

          </Col>

          <Row className={`pt-3 mt-4 ${style.m5}`}>

            {service.map((data) => (
              <>

                <>
                  <Col xs={6} sm={6} md={3} className={style.serviceCont} >
                    <Card className={`center ${style.card} OMR{style.ServiceIcon}`} id="otherService">
                      <Card.Body className={style.cardBody}>
                        <Card.Title className={style.ServiceIcon}>
                          <div className={` ${style.ImgCon}`}>
                            <img alt="" src={BASE_URL + '/service_cat_images/' + data.image} className={style.cardImg} />
                          </div>
                        </Card.Title>
                        <Card.Title className={`${style.serviceName}`}>{localStorage.getItem('language') == 'ar' ? data.name_ar && data.name_ar : data.name && data.name}</Card.Title>
                        <Card.Text className={` ${style.des}`} dangerouslySetInnerHTML={{ __html: localStorage.getItem('language') == 'ar' ? data.description_ar && data.description_ar : data.description && data.description }}></Card.Text>

                        <Drawer
                          name={<img alt="" src={RightIcon} className={`pointer ${style.rightIcon}`} onClick={() => { setShow(true); setServiceId(data.id); changePrice(data.id) }} />}
                          title={localStorage.getItem('language') == 'ar' ? data.name_ar && data.name_ar : data.name && data.name}
                          description={localStorage.getItem('language') == 'ar' ? data.description_ar && data.description_ar : data.description && data.description}
                          className="no-background">

                          {data.services.length == 0 ? <h3 className="mt-4 center">No Services Available</h3> :
                            <>

                              <Col md={12} xs={12}>
                                {selectedCarMake ?
                                  <Row>
                                    <Col md={6} xs={6}>
                                      <div className="flex  mt-2">
                                        <h5>{t("appointDropdown.make")}:</h5>
                                        <h5 className={style.ml10}>{selectedCarMake}</h5>
                                      </div>
                                    </Col>
                                    <Col md={6} xs={6} className={style.alignRight}>
                                      <img src={EditIcon} className={style.EditIcon} onClick={() => setEditMake(!editMake)} />
                                    </Col>
                                    {editMake &&
                                      <Col>
                                        <Form.Select defaultValue={0} className="mt-2 mb-2 input-drawer" size="md" onChange={(e) => {getcartempty(e.target.value);}}>
                                          <option value="0">{t("menu.serviceDetail.ac.selectMake")}</option>
                                          {carmake.map((item) => (
                                            <option key={item.id} value={item.id + "_" + item.name}>{localStorage.getItem('language') == 'ar' ? item.name_ar && item.name_ar : item.name && item.name}</option>
                                          ))}
                                        </Form.Select>
                                      </Col>
                                    }
                                  </Row>
                                  : <Col>
                                    <Form.Select defaultValue={0} className="mt-4 input-drawer" size="md" onChange={(e) => {getcartemptymodel(e.target.value);}}>
                                      <option value="0">{t("menu.serviceDetail.ac.selectMake")}</option>
                                      {carmake.map((item) => (
                                        <option key={item.id} value={item.id + "_" + item.name}>{localStorage.getItem('language') == 'ar' ? item.name_ar && item.name_ar : item.name && item.name}</option>
                                      ))}
                                    </Form.Select>
                                  </Col>}
                                {selectedCarModel ?
                                  <Row>
                                    <Col md={6} xs={6}>
                                      <div className="flex mt-2">
                                        <h5>{t("appointDropdown.model")}:</h5>
                                        <h5 className={style.ml10}>{modelName}</h5>
                                      </div>
                                    </Col>
                                    <Col md={6} xs={6} className={style.alignRight}>
                                      <img src={EditIcon} className={style.EditIcon} onClick={() =>{ setEditModel(!editModel); setPrice(null)}} />
                                    </Col>
                                    {editModel && <Col>
                                      <Form.Select defaultValue={0} className="mt-2 mb-2 input-drawer" size="md" onChange={(e) => {getcartemptymodel(e.target.value);}}>
                                        <option value="0">{t("menu.serviceDetail.ac.selectModel")}</option>
                                        {carmodel.map((item) => (
                                          <option className="max-content" key={item.id} value={item.id + "_" + item.name}>{localStorage.getItem('language') == 'ar' ? item.name_ar && item.name_ar : item.name && item.name}</option>
                                        ))}
                                      </Form.Select>
                                    </Col>}
                                  </Row>

                                  :
                                  <Col>
                                    <Form.Select defaultValue={0} className="mt-4 input-drawer" size="md" onChange={(e) => getcartemptymodel(e.target.value)}>
                                      <option value="0">{t("menu.serviceDetail.ac.selectModel")}</option>
                                      {carmodel.map((item) => (
                                        <option className="max-content" key={item.id} value={item.id + "_" + item.name}>{localStorage.getItem('language') == 'ar' ? item.name_ar && item.name_ar : item.name && item.name}</option>
                                      ))}
                                    </Form.Select>
                                  </Col>
                                }

                                {selectedFuel ?
                                  <Row>
                                    <Col md={6} xs={6}>
                                      <div className="flex mt-2" >
                                        <h5>{t("appointDropdown.fuel")}:</h5>
                                        <h5 className={`max-content ${style.ml10}`} >{selectedFuel}</h5>
                                      </div>
                                    </Col>
                                    <Col md={6} xs={6} className={style.alignRight}>
                                      <img src={EditIcon} className={style.EditIcon} onClick={() => {setEditFuel(!editFuel); setPrice(null)}} />
                                    </Col>
                                    {editFuel &&
                                      <Col>
                                      <Form.Select defaultValue={0} className="mb-2 mt-2 input-drawer" size="md" onChange={(e) => {fetchFuels(e.target.value);localStorage.setItem('fuel',e.target.value)}} onClick={() => changePrice(serviceId)}>
                                        <option value="0">{t("menu.serviceDetail.ac.selectfuel")}</option>
                                        {fuelType.map((item) => (
                                          <option className="max-content" key={item.id} value={item.name}>{item.name}</option>
                                        ))}
                                      </Form.Select>
                                      </Col>
                                    }
                                  </Row>
                                  :
                                  <Col>
                                    <Form.Select defaultValue={0} className="mt-4 input-drawer" size="md" onChange={(e) => {setSelectedFuel(e.target.value);localStorage.setItem('fuel',e.target.value)}} onClick={() => changePrice(serviceId)}>
                                      <option value="0">{t("menu.serviceDetail.ac.selectfuel")}</option>
                                      {fuelType.map((item) => (
                                        <option key={item.id} value={item.name}>{item.name}</option>
                                      ))}
                                    </Form.Select>
                                  </Col>
                                }

                              </Col>
                              <div>

                                {price ?

                                  price.map((data) => (
                                    <>


                                      <>
                                        <Card className="mt-4 p-2">
                                          {data.service_options && data.service_options.length > 0 ?
                                            <>
                                              <Card.Title className={style.space}>
                                                <div className={style.serviceName}>{localStorage.getItem('language') == 'ar' ? data.name_ar && data.name_ar : data.name && data.name}</div>

                                                <div className={style.time}><img src={Time} alt="" className={style.timeIcon} /> {data.service_time && data.service_time} Hrs </div>
                                              </Card.Title>
                                              <Card.Body className="p0">
                                                <div className={`${style.des}`}>{data.description.length > 50 ? data.description.substring(0, 140) + '...' : data.description && data.description}</div>

                                                <div className={`mt-2 ${style.space}`}>
                                                  
                                                  <Button variant="primary" onClick={selectedCarMake && selectedCarModel && selectedFuel ? token ? () => handleSubmit(data.service_options[0].id, data.service_options[0].service_id,) : () => handleSubmitOffline(data.service_options[0].id, data.service_options[0].service_id,) : () => alert("Please select Car Make & Model to proceed further")}>{t("productTab.cartBtn")}</Button>
                                                </div>
                                              </Card.Body>
                                            </>
                                            : <h3>Not Avalailable</h3>}
                                        </Card>


                                      </>
                                    </>)) : price == null ?  <Card className="mt-4 p-2"><h3>Not Avalailable</h3></Card> : 

                                  data.services.map((d) => (
                                    <>
                                      <Card className="mt-4 p-2">
                                        <Card.Title className={style.space}>
                                          <div className={style.serviceName}>{localStorage.getItem('language') == 'ar' ? d.name_ar : d.name}</div>

                                          <div className={style.time}><img src={Time} alt="" className={style.timeIcon} /> {d.service_time} Hrs </div>
                                        </Card.Title>
                                        <Card.Body className="p0">
                                          <div className={`${style.des}`}>{d.description.length > 50 ? d.description.substring(0, 140) + '...' : d.description && d.description}</div>

                                          <div className={`mt-2 ${style.space}`}>
                                            

                                            <div className={style.discountPrice}>OMR{d.service_options[0].price}</div>
                                            <Button variant="primary" onClick={selectedCarMake && selectedCarModel && selectedFuel ? token ? () => handleSubmit(d.service_options[0].id, d.service_options[0].service_id,) : () => handleSubmitOffline(d.service_options[0].id, d.service_options[0].service_id,) : () => alert("Please select Car Make & Model to proceed further")}>{t("productTab.cartBtn")}</Button>
                                          </div>
                                        </Card.Body>
                                      </Card>

                                    </>
                                  ))}
                              </div>
                              <Button className="ProcessBtn mt-4" onClick={() => ProceedValueSummary()}>{t("productTab.proceedCart")}</Button>
                            </>
                          }
                        </Drawer>
                      </Card.Body>

                    </Card>
                  </Col>
                </>

              </>
            ))}

          </Row>
          <Modal show={open} onHide={() => setOpen(false)} centered>
          
            <Modal.Body>
              <div className='center mt-2'>{modalData ? modalData.meassge && modalData.meassge == "Already Added To Cart" ?
                <div className="text-danger">You have already added this product in the cart, do you want to proceed to cart? <span className="pointer text-blue" onClick={() => ProceedValueSummary()}>Yes</span> </div>
                :
                modalData.meassge : ''}</div>
            </Modal.Body>

          </Modal>
        </Container>
      </Col>
      <Footer />
    </div>
  );
};

export default OtherServices;

