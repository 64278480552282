import React,{useEffect,useState} from "react";
import Header from "../components/Header/Header_new";
import Appbar from "../components/Appbar/Appbar";
import Footer from "../components/Footer/Footer";
import Menubar from "../components/Navbar/Menubar_new"
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Accordion
} from "react-bootstrap";
import CarImg from "../images/carimg.jpg";
import { useTranslation } from "react-i18next"
import axios from "axios";
import { BASE_URL } from "../config";

const Faq = () => {
  const [datas,setData]=useState([]);
  useEffect(()=>{
fetchfaqdata();
  },[])
  const fetchfaqdata = async()=>{
    await axios.get(BASE_URL+'/api/faqs').then(({data})=>{
      setData(data.data)
    })
  }
  const {t} = useTranslation();
    return (
        <div className="overflow">
            <Header />
            <Menubar />

            <Container fluid className="company-breadcrumb">
                <Row>
                    <Col className="breadcrumb-padding breadcrumb-active-new" xs={12} md={8}>
                        <h1>{t("custLinks.faq")}</h1>
                        <Breadcrumb>
                        <Breadcrumb.Item href="/">{t("menu.home")}</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                            <div className="blog-breadcrumb-active">{t("custLinks.faq")}</div>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col xs={12} md={4}>
                        <img src={CarImg} alt="" width="387px" className="mobile-hidden" />
                    </Col>
                </Row>
            </Container>

            <Container className="termsCond">
                <h1 className="text-center">{t("appFaq.faq")}</h1>

               <Container className="faq-accordion">
               <Row>
                {datas && datas.map((d,key)=>(
                  <Col xs={12} md={6}>
            <Accordion className="accordion-margin">
              <Accordion.Item eventKey={key}>
                <Accordion.Header>
                  {localStorage.getItem('language') == 'ar'?  d.question_ar &&  d.question_ar :  d.question && d.question}
                </Accordion.Header>
                <Accordion.Body>
                {localStorage.getItem('language') == 'ar'?  d.answer_ar &&  d.answer_ar :  d.answer && d.answer}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
                ))}
          

          
        </Row>
               </Container>

            </Container>

            <Footer />
        </div>
    );
};

export default Faq;
