import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CountUp from 'react-countup';
import Slider from "react-slick";
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";
// import Footer from "../components/Footer/Footer";
import {
    Container,
    Button,
    Row,
    Col,
    Form,
    Card,
    Nav,

} from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../config";

import style from '../../styles/ourservice.module.css';
import Search from '../../images/Icons/Search.png';
import Time from '../../images/Icons/time.png';
import SettingIcon from "../../images/settingicon.svg";
import CallSupportIcon from "../../images/supportlineicon.svg";
import BarChartIcon from "../../images/barcharticon.svg";
import Swal from "sweetalert2";
import Drawer from '../Drawer/Drawer';
import swal from "sweetalert";
import { UserContext } from "../../App";
export default function OtherServiceHomepage({ otherServiceHeading, otherServiceContent, serviceContent, carService, valueAddedHeading, valueAddedContent, carImage, brandName, brandContent, shippingName, shippingContent, dealershipContent, dealershipName }) {
    const { t } = useTranslation();

   
    const settings = {
        dots: true,
        infinite: true,
        autoplay: false,
        speed: 100,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 400, // For screens larger than 1024px
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 768, // For screens larger than 768px
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],

    };

    const navigate = useNavigate();

    const language = localStorage.getItem("language");
    const [show, setShow] = useState(false);

    const [menufacturer, setMenufacturer] = useState(false);
    const [fuel, setFuel] = useState(false);
    const [model, setModel] = useState(false);
    const [otherServiceData, setOtherServiceData] = useState([])
    const [error, setError] = useState();
    const token = localStorage.getItem('token');
    const [deviceId, setDeviceId] = useState('');
    const [service, setSevice] = useState([]);
    const [carmake, setCarMake] = useState([]);
    const [carmodel, setCarModel] = useState([]);
    const [carOrigin, setCarOrigin] = useState([]);
    const [carCylinder, setCarCylinder] = useState([]);
    const [oilgrade, setOilGrade] = useState([]);
    const models = new String(localStorage.getItem('carmodel'))
    const carmodeldata = models.split("_")
    const modelName = carmodeldata[1]
    const modelid = carmodeldata[0]
    const carmakes = new String(localStorage.getItem('carmake'))
    const carmakesdata = carmakes.split("_")
    const carmakename = carmakesdata[1]
    const carmakeid = carmakesdata[0]
    const [selectedCarMake, setSelectedCarMake] = useState(carmakename);
    const [selectedCarModel, setSelectedCarModel] = useState(modelName);
    const [selectedFuel, setSelectedFuel] = useState(localStorage.getItem('fuel'));
    const [showService, setShowService] = useState();
    const [modalData, setModalData] = useState();
    const [open, setOpen] = useState();
    const [x, setX] = useState();
    const [valueopen, setValueOpen] = useState();
    const [price, setPrice] = useState();
    const [carmakeId, setCarMakeId] = useState(carmakeid);
    const [carmodelId, setCarModelId] = useState(modelid);
    const [serviceId, setServiceId] = useState();
    const value = selectedCarMake ? [selectedCarMake, selectedCarModel, selectedFuel] : [t("menu.serviceDetail.ac.selectMake") + " , " + t("appointDropdown.model") + " & " + t("appointDropdown.fuel")];
    localStorage.setItem('selectedCarMake', selectedCarMake)
    localStorage.setItem('selectedCarModel', selectedCarModel)
    localStorage.setItem('selectedFuel', selectedFuel)
    localStorage.setItem('serviceType', "other")
    const { itemservice, setItemService, servicetype, setServiceType } = useContext(UserContext);

    const handle = async (id) => {
        setMenufacturer(false)
        setModel(true)

        await axios.get(BASE_URL + '/api/car-model/' + id, {}).then(({ data }) => {

            setCarModel(data.data);

        })

    }

    const handleFuel = () => {
        setModel(false)
        setFuel(true)
    }

    useEffect(() => {
        fetchservice();
        getMachineId();
        fetchValueservice();
        fetchCarMake();
        fetchOrigin();
        fetchCylinder();
        fetchOilGrade();
    }, [])

    function getcartempty() {
        swal({
            title: "Are you sure you want to change the Car Make & Model?",
            text: "If you do this Services added in your cart will be removed",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((value) => {
                if (value == true) {
                    if (token) {
                        handledelete();
                    } else {
                        handledeleteunauth();
                    }
                    setMenufacturer(true);
                } else {
                    setMenufacturer(false)
                }
            });
    }
    function handledelete() {
        axios.post(BASE_URL + '/api/remove-all-item-from-cart-service', '', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setItemService(0)
        })
    }

    function handledeleteunauth() {
        const data = { "device_id": deviceId };
        axios.post(BASE_URL + '/api/remove-all-item-from-cart-service-unauth', data).then(({ data }) => {
            setItemService(0)
        })
    }
    const fetchservice = async () => {

        await axios.get(BASE_URL + '/api/other-services').then(({ data }) => {

            setOtherServiceData(data.data);

        })
    }


    const fetchValueservice = async () => {

        await axios.get(BASE_URL + '/api/value-added-service').then(({ data }) => {

            setSevice(data.data);

        })
    }

    const fetchCarMake = async () => {

        await axios.get(BASE_URL + '/api/car-make').then(({ data }) => {

            setCarMake(data.data);

        })
    }

    const fetchOrigin = async () => {
        await axios.get(BASE_URL + '/api/car-origin/').then(({ data }) => {

            setCarOrigin(data.data);

        })
    }

    const fetchCylinder = async () => {
        await axios.get(BASE_URL + '/api/car-cylinder/').then(({ data }) => {

            setCarCylinder(data.data);

        })

    }

    const fetchOilGrade = async () => {
        await axios.get(BASE_URL + '/api/oil-grade/').then(({ data }) => {

            setOilGrade(data.data);

        })

    }


    function getMachineId() {

        let machineId = localStorage.getItem('MachineId');

        if (!machineId) {
            machineId = crypto.randomUUID();
            localStorage.setItem('MachineId', machineId);
        }

        setDeviceId(machineId);

    }

    function handleSubmit(id, option) {
        x ? setOpen(true) : setValueOpen(true)
        setError('');
        if (servicetype == "value_added_services") {
            handledelete();
        }
        const data = { 'service_id': option, 'service_option_id': id, 'service_type': "other_services" }
        axios.post(BASE_URL + '/api/store-cart-service', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setModalData(data)
            setServiceType('other_services');
            localStorage.setItem('service', 'other_services')
            setItemService(data.item)
            if (data.status === "success") {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                navigate("/")
                document.getElementById("otherService").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }
    function handleSubmitValue(id, option) {
        x ? setOpen(true) : setValueOpen(true)
        setError('');
        if (servicetype == "other_services") {
            handledelete();
        }
        const data = { 'service_id': option, 'service_option_id': id, 'service_type': "value_added_services" }
        axios.post(BASE_URL + '/api/store-cart-service', data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setModalData(data)
            setItemService(data.item)
            setServiceType('value_added_services')
            localStorage.setItem('service', 'value_added_services')
            if (data.status === "success") {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                navigate("/")
                document.getElementById("otherService").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    function changePrice(data) {
        setError('');
        axios.post(BASE_URL + '/api/filter-services', {
            "service_id": data ? data : serviceId,
            "brand_id": carmakeId,
            "brandmodel_id": carmodelId
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setPrice(data.data)
            if (data.status === "success") {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
            }
        })
    }


    console.log(price, "price");
    function handleSubmitOffline(id, option) {
        setOpen(true)
        setError('');
        if (servicetype == "value_added_services") {
            handledeleteunauth();
        }
        const data = { "device_id": deviceId, 'service_id': option, 'service_option_id': id, 'service_type': "other_services" }
        axios.post(BASE_URL + '/api/store-cart-service-unauth', data).then(({ data }) => {
            setModalData(data)
            setServiceType('other_services')
            localStorage.setItem('service', 'other_services')
            setItemService(data.item)
            if (data.status === "success") {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                navigate("/")
                document.getElementById("otherService").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    function handleSubmitOfflineValue(id, option) {
        setOpen(true)
        setError('');
        if (servicetype == "other_services") {
            handledeleteunauth();
        }
        const data = { "device_id": deviceId, 'service_id': option, 'service_option_id': id, 'service_type': "value_added_services" }
        axios.post(BASE_URL + '/api/store-cart-service-unauth', data).then(({ data }) => {
            setModalData(data)
            setItemService(data.item)
            setServiceType('value_added_services')
            localStorage.setItem('service', 'value_added_services')
            if (data.status === "success") {
                setError('')
                Swal.fire({
                    text: data.message,
                    icon: "success"
                })
                navigate("/")
                document.getElementById("otherService").reset();
            }
            if (data.status === "error") {
                Swal.fire({
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(({ response }) => {
            setError(response.data)
            if (response.status === "422") {
                setError(response.data.message)
            }
        });
    }

    
    

    console.log(otherServiceData, "other services");

    return (

        <Col md={12} xs={12}>
            <Col md={12} xs={12}>

                <Container fluid className="serviceContainer">
                    <div className={`text-center ${style.serviceHeading}`}>{otherServiceHeading}</div>
                    <div className={`text-muted text-center  ${style.Servicedes}`} dangerouslySetInnerHTML={{ __html: otherServiceContent }}>

                    </div>
                    <div>

                    </div>
                    <div className="container">

                        <Row className="mt-3">
                      
                        <Slider {...settings}>
                                {otherServiceData.map((data) => (
                                    <>
                                        <>
                                            <div >
                                                <Card className={`center ${style.card} OMR{style.ServiceIcon}`} id="otherService">
                                                    <Card.Body className={style.cardBody}>

                                                        
                                                       
                                                        <Card.Title className={style.ServiceIcon}>
                                                            <div className={` ${style.ImgCon}`}>
                                                                <img alt="" src={BASE_URL + '/service_cat_images/' + data.image} className={style.cardImg} />
                                                            </div>
                                                        </Card.Title>
                                                        
                                                        <Nav.Link href={data.slug} className={`${style.serviceName}`}>{localStorage.getItem('language') == 'ar' ? data.name_ar && data.name_ar : data.name && data.name} </Nav.Link>
                                                        
                                                    </Card.Body>
                                                    
                                                </Card>
                                            </div>

                                        </>
                                    </>
                                ))} 
                            </Slider> 
                            
                        </Row>
                        
                    </div>
                </Container>
              

            </Col>
            <br />
            <Container className="mt-4 mb-5">
                <div className="container">
                    <Row>
                        <Col xs={12} md={6}>
                            <div className={style.CarSerHead}>{carService}</div>
                            <div className={`text-muted ${style.CarSerCont}`} pt-2 dangerouslySetInnerHTML={{ __html: serviceContent }}>
                            </div>
                            
                            
                        </Col>
                        <Col xs={12} md={6}>
                            <img src={carImage} alt="" className="carBuild" />
                        </Col>
                    </Row>
                </div>
                
            </Container>
            
        </Col>
    );
};