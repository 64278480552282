import React, { useState, useEffect, useContext } from 'react';
import ProductBox from '../ProductBox/Productbox';
import axios from 'axios';
// import { Col, Modal } from 'react-bootstrap';
import Product from '../../images/product.png';
import { BASE_URL } from '../../config';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Col, Nav, Container, Card, Button, Row, Modal, Form, ModalHeader, ModalBody } from 'react-bootstrap';
import { UserContext } from '../../App';

function BestSellersCategory() {

  useEffect(() => {
    fetchbestsellerscategory(0);

  }, []);

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [error, setError] = useState();
  const [show, setShow] = useState();
  const [carMake, setCarMake] = useState([]);
  const [carModel, setCarModel] = useState([]);
  const [make, setMake] = useState(false);
  const [modal, setModal] = useState(false);
  const [state, setState] = useState();
  const [stateId, setIdState] = useState();
  const [carData, setCarData] = useState(false);
  const [slugId, setSlugId] = useState();

  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  let machineId = localStorage.getItem('MachineId');

  const [products, setProducts] = useState([]);
  const { item, setItem } = useContext(UserContext);
  async function fetchbestsellerscategory(id) {

    axios.get(BASE_URL + '/api/best-deal-product/'+ id, {
    }).then(({ data }) => {

      setProducts(data.data);

    })
  }


  function handleSubmitOffline(id, option) {
    setOpen(true)
    setShow(false)
    const data = { "device_id": machineId, "product_id": id, "product_option_id": option }
    axios.post(BASE_URL + '/api/store-cart-unauth', data).then(({ data }) => {
      setModalData(data)
      setItem(data.item);
      if (data.status === "success") {
        setError('')

        Swal.fire({
          text: data.message,
          icon: "success"
        })
        navigate("/shopping-cart")
        document.getElementById("otherService").reset();

      }
      if (data.status === "error") {
        Swal.fire({
          text: data.message,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data)
      if (response.status === "422") {
        setError(response.data.message)
      }
    });
  }

  function handleSubmit(id, option) {
    setOpen(true)
    setShow(false)
    setError('');
    const data = { 'product_id': id, 'product_option_id': option }
    axios.post(BASE_URL + '/api/store-cart', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setItem(data.item);
      setModalData(data)
      if (data.status === "success") {
        setError('')
        Swal.fire({
          text: data.message,
          icon: "success"
        })

        navigate("/")
        document.getElementById("otherService").reset();
      }
      if (data.status === "error") {
        Swal.fire({
          text: data.message,
          icon: "error"
        })
      }
    }).catch(({ response }) => {
      setError(response.data)
      if (response.status === "422") {
        setError(response.data.message)
      }
    });
  }

  return (
    <div className="container-fluid">
  <Row >
      {products.map(i => (
        <Col md={3} sm={4} xs={6} className="mt-4 mb-4">
          <ProductBox
            name={localStorage.getItem('language') == 'ar' ? i.name_ar && i.name_ar : i.name && i.name}
            img={i.image}
            BtnCart={t("productTab.cartBtn")}
            
            productDetail={localStorage.getItem('cardata') && localStorage.getItem('carmodeldata') ? () => navigate("/product-detail/" + i.slug) : () => {navigate("/product-detail/" + i.slug)}}
          />
        </Col>
      ))}
      </Row>
      
    </div>

  )
}

export default BestSellersCategory;